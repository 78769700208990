import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="iOS Developer"
      pageUrl="/careers/ios/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="You are the kind of person that follows religiously every Apple event. WWDC feels like Christmas to you and everything is better with the Apple logo on it, Let's talk!"
    />
    <LdJsonJobPosting
      title="iOS Developer"
      description="You are the kind of person that follows religiously every Apple event. WWDC feels like Christmas to you and everything is better with the Apple logo on it, Let's talk!"
      minSalary="5500.00"
      maxSalary="7500.00"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">iOS Developer</h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            You are the kind of person that follows religiously every Apple event. WWDC feels like Christmas to you. Some call you a fanboi but you don't mind as{' '}
            <strong className="text-primary-1 font-normal">you know in detail how the iOS platform works</strong>. When Swift was released, it got you excited - a new language for the modern world. Every new iteration perfects it - makes it better, opens new
            possibilities and leads to new ideas.
          </p>
          <p className="sm:mt-6 font-normal">We do too!</p>
          <p className="sm:mt-6 font-normal">
            To all like-minded enthusiasts, <strong className="text-primary-1 font-normal">let's talk</strong> and explore together the Future ahead!
          </p>
          <p className="sm:mt-6">
            What we'd like to do <strong className="text-primary-1">together</strong>:
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Innovate in the field of digital publishing.</li>
            <li>Find new ways to bring information to the world's top business entities.</li>
            <li>Never stop innovating!</li>
          </ul>
          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Design, build, test</strong> and distribute advanced applications for iOS platform.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Contribute</strong> to UX discussions and decisions to make sure the applications are intuitive, simple and in compliance with Apple's Human Interface Guidelines.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Collaborate</strong> with the team and clients to define, design and ship new features.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Guides</strong> junior developers and removes any impediments that they might encounter.
                </li>
                <li className="mt-4">Unit-test code for robustness, including edge cases, usability and general reliability.</li>
                <li className="mt-4">Identify opportunities for improving both the software and the development methodologies and procedures and communicate recommendations appropriately.</li>
                <li className="mt-4">Continuously discover, evaluate and implement new technologies to maximize development efficiency.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Proficient</strong> in Swift and the iOS platform.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">5+ years</strong> of software development experience.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">3+ years</strong> of iOS development, most if not all of it, using Swift.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> of SwiftUI, Combine, UIKit, and Core Data.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> of the concurrency model in Swift, including async/await, structured concurrency, and Actors.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of the general mobile landscape, architectures, trends and emerging technologies.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of the full mobile development life cycle and the experience of actively participating in project/product creation and distribution.
                </li>
                <li className="mt-4">Setup or maintain CI/CD systems and pipelines.</li>
                <li className="mt-4">Self-organized, proactive and good communicator.</li>
                <li className="mt-4">Able to look through the customer lens.</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="iOS Developer" active={true} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
